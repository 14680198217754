import React from 'react'
import Layout from '../components/layout'
import YoutubePlayer from "../components/youtube"

const YoutubePage = () => (
  <Layout fullwidth={false}>
  <div>
 <YoutubePlayer/>
</div>
  </Layout>
)

export default YoutubePage
